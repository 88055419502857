<template>
  <div class="w-64 mt-6 mb-8">
    <CommonSpinner />
    <span class="text-sm text-center font-serif block">
      <template v-if="!$slots.default">{{
        $t('trialSignup.loading.pleaseWait')
      }}</template>
      <slot />
    </span>
  </div>
</template>
